<template>
	<div>


		<div class="processbox">
			<div class="label">
				<div class="rowlabel"></div>
				审批流程
			</div>
			<div class="steps">
				<div class="step" v-for="(item,index) in processSteps" :key="index">
					<div class="sp_type">
						<span v-if="item.sp_type=='one'">指定人员审批</span>
						<span v-if="item.sp_type=='dept_leader'">发起人部门领导审批</span>
						<span v-if="item.sp_type=='fgxz'">分管校长审批</span>
						<span v-if="item.sp_type=='dept_role'">按部门角色审批</span>
						<span v-if="item.sp_type=='sys_role'">按系统角色审批</span>
						<span v-if="item.sp_type=='yuxuan'">从预选中选择审批人</span>
						<span v-if="item.sp_type=='notsure'">审批人自选</span>
						<span class="steptype" v-if="item.sp_users&&item.sp_users.length>1&& item.step_type=='and'">会签</span>
						<span class="steptype" v-if="item.sp_users&&item.sp_users.length>1&&item.step_type=='or'">或签</span>
						<span class="steptype" v-if="item.sp_users&&item.sp_users.length>1&&item.step_type=='notsure'">自选</span>
					</div>

					<div class="spusers">
						<div v-if="item.sp_users&&item.sp_users.length>0">

							<div class="spuseritem" v-for="(spuser,idx) in item.sp_users" :key="idx">


								<div :class="['user', spuser.spover==2?'spfail':'']">
									<img :src="spuser.avatar||tempbg" />
									<span>{{spuser.username}}</span>
								</div>
								<div class="handsign">

									<img :src="spuser.handsign" />
								</div>
								<div class="handsign"
									style="width: auto; line-height: 30px; font-size: 10px; margin-left: 25px; color: #ccc;">
									{{spuser.sptime}}
								</div>

								<div :class="['spword',  spuser.spover==2?'spfail':'']" v-if="spuser.spword">
									{{spuser.spword}}
								</div>
							</div>

						</div>

					</div>

					<div :class="['line', item.spover?'pass':'']">
						<div :class="['linepoint', item.spover?'pass':'']">{{index+1}}</div>

					</div>

				</div>

				<div class="step">
					<div class="sp_type">
						<span style="color: chocolate;">流程结束 {{ccUsers&&ccUsers.length>0?'（抄送）':''}}</span>
					</div>
					<div class="spusers">
						<div v-if="ccUsers&&ccUsers.length>0">
							<div class="user" v-for="(item,index) in ccUsers" :key="index">
								<img :src="item.avatar||tempbg" />
								<span>{{item.username}}</span>
							</div>
						</div>
					</div>
					<div class="linepoint" style="top: 17px; background-color: chocolate;">{{processSteps.length+1}}
					</div>
				</div>
			</div>
		</div>

		<div style="text-align: right; width: 150%;transform: translateX(-35%);" v-if="icansp">


			<el-input v-model="spInfo.spword" placeholder="审批留言、指示、意见等" size="small"
				style="width: 400px; margin-right: 20px;" />
			<el-button type="danger" icon="el-icon-back" size="small" @click="checkNextSp(2)">审批驳回</el-button>
			<el-button type="primary" icon="el-icon-folder-checked" size="small" @click="checkNextSp(1)">审批通过
			</el-button>
		</div>



		<div v-if="showSign"
			style="position: fixed; top: 0; left: 0; background-color: #000000; width: 100%; height: 100%; z-index: 999; display: flex; align-items: center;">
			<div style="width: 800px; margin: 0 auto;">
				<div style="text-align: center; color: #fff; font-size: 20px;">
					请在空白处签名
					<div style="float: right; cursor: pointer;" @click="showSign=false">
						<i class="el-icon-close"></i>
					</div>
				</div>
				<div style="height: 400px; width: 800px; background-color: #fff;">


					<vue-esign ref="esign" :width="800" :height="400" :isCrop="isCrop" :lineWidth="lineWidth"
						:lineColor="lineColor" :bgColor.sync="bgColor" />
				</div>

				<el-button @click="handleReset" type="danger" size="small" icon="el-icon-magic-stick">清空画板</el-button>
				<el-button style="float: right;" @click="handleGenerate" type="primary" size="small"
					icon="el-icon-edit">确认签名</el-button>
			</div>
		</div>
		<!-- 这里改 -->
		<deptuser module="zichan" :odata="nextProcess.sp_users" :type="nextProcess.sp_type" :mult="true"
			v-if="showDeptUser" v-on:getUser="getUser"></deptuser>


	</div>

</template>

<script>
	import deptuser from '../com/deptuser'
	export default {
		props: ["forminfo"],
		components: {
			deptuser
		},

		data() {
			return {
				form_id: 0,
				process_json: [],
				showDeptUser: false,
				tablename: 'zichan', //这里改
				cc: [],
				tempbg: "/static/tempbg.png",
				processSteps: [],
				spTemplate: {},
				showSpusers: false,
				nowProcess: null,
				nextProcess: null,
				icansp: false,
				ccUsers: [],
				lineWidth: 6,
				lineColor: '#000000',
				bgColor: '',
				resultImg: '',
				isCrop: false,
				showSign: false,
				isNeedSptype: false,
				isNeedChoose: false,
				DeptUserDialog: false,
				checkedUsers: [],
				spInfo: {
					spword: "",
					sp_result: "",
					handsign: ""
				}


			};
		},
		mounted() {

			this.form_id = this.forminfo.id
			this.processSteps = this.forminfo.process_json
			this.cc = this.forminfo.cc
			this.ccUsers = this.forminfo.cc
			console.log(this.ccUsers)

			this.checkIcanSP()
			this.getNextStep()

		},
		methods: {

			checkIcanSP() {
				this.$http.post("/api/get_i_can_sp", {
					form_id: this.form_id,
					table_name: this.tablename
				}).then(res => {
					this.icansp = res.data.icansp



				})
			},
			getNextStep() {
				this.$http.post("/api/get_next_step", {
					form_id: this.form_id,
					table_name: this.tablename
				}).then(res => {
					if (res.data.next) {
						this.nextProcess = res.data.next
						console.log(res.data.next)
						let isNeedChoose = false;
						let isNeedSptype = false


						if (this.nextProcess.sp_type == "yuxuan" || this.nextProcess.sp_type ==
							"notsure") {
							isNeedChoose = true

						}
						if (this.nextProcess.step_type == "notsure") {
							isNeedSptype = true

						}




						let processSteps = this.processSteps
						for (let step of processSteps) {
							step.spover = 0;
							if (step.sp_users) {
								if (step.step_type == "or") {
									for (let u of step.sp_users) {
										if (u.spover) {
											step.spover = 1;
										}
									}
								} else {
									let allok = 0;
									for (let u of step.sp_users) {
										if (u.spover) {
											allok++
										}
									}
									if (allok == step.sp_users.length && step.sp_users.length > 0) {
										step.spover = 1;
									}
								}

							}


						}
						this.processSteps = processSteps
						this.isNeedChoose = isNeedChoose;
						this.isNeedSptype = isNeedSptype
					}
				})
			},


			receiveChsData(e) {
				if (e.detail.sp_users.length > 0) {

					this.triggerEvent("sendData", {
						type: "checkedUsers",
						data: e.detail.sp_users
					});
					this.triggerEvent("sendData", {
						type: "step_type",
						data: e.detail.step_type
					});
				} else {
					this.triggerEvent("sendData", {
						type: "checkedUsers",
						data: []
					});
				}

				this.setData({
					showSpusers: false
				})

			},
			checkNextSp(type) {
				this.spInfo.sp_result = type
				if (type == 1) {
					if (this.isNeedChoose && this.checkedUsers.length == 0) {
						this.showDeptUser = true
					} else {
						this.showSign = true
					}
				} else if (type == 2) {
					this.showSign = true
				}


			},
			getUser(e) {
				if (e) {
					this.nextProcess.sp_users = e
					this.showSign = true
				}
				this.showDeptUser = false

			},
			handleReset() {
				this.$refs.esign.reset()

			},
			handleGenerate() {
				this.$refs.esign.generate().then(res => {

					this.$http.post("/api/save_base64_img", {
						base64_img: res
					}).then(res1 => {
						//spword:this.data.spword,sp_result:sp_result,handsign:this.data.signImg

						this.spInfo.handsign = res1.data.url
						this.spInfo.form_id = this.form_id

						let nextProcess = this.nextProcess
						if (!nextProcess) {
							nextProcess = {}
						}
						if (nextProcess) {
							this.spInfo.step_type = nextProcess.step_type
						}

						if (this.spInfo.step_type == "notsure") {
							this.spInfo.step_type = "and"
						}
						if (nextProcess.sp_users && nextProcess.sp_users.length > 0) {
							this.spInfo.checkedUsers = nextProcess.sp_users

						}
						this.$http.post("/api/zichan_form_sp", this.spInfo).then(res2 => {
							if (res2.data && res2.data.code == 200) {
								this.$message.success("审批成功")
								this.showDeptUser = false
								this.showSign = false
								//发送通知接口，调用即可
								this.$http.post("/api/zichan_send_sp_next_msg", this.spInfo)
									.then(res => {

									})

								this.$parent.$parent.$parent.dialogspshow = false
								this.$parent.$parent.$parent.getList()

							}
						})




					})


				}).catch(err => {
					alert(err) // 画布没有签字时会执行这里 'Not Signned'
				})
			},


		}
	}
</script>

<style scoped=" scoped">
	.processbox {
		margin: 0 auto;
		font-size: 14px;
		min-height: 500px;
	}

	.label {
		border: 1px solid #ccc;
		border-left: 0;
		border-right: 0;
		padding: 10px 5%;
	}

	.label2 {
		padding: 5px 2%;
	}

	.rowlabel {
		display: inline-block;
		width: 4px;
		height: 14px;
		background-color: #3BB0F2;
		transform: translateY(2px);
	}

	.steps {
		width: 85%;
		margin: 0 auto;
	}

	.step {
		position: relative;
		padding-bottom: 10px;
		padding-top: 15px;
	}

	.line {
		position: absolute;
		left: 0;
		top: 17px;
		z-index: 0;
		height: 100%;
		width: 1px;
		background-color: #ccc;

	}

	.sp_type {
		position: relative;
		z-index: 1;
		padding-left: 15px;
	}

	.linepoint {
		width: 15px;
		height: 15px;
		border-radius: 7px;
		background-color: #ccc;
		color: #FFFFFF;
		left: -7px;
		position: absolute;
		line-height: 15px;
		text-align: center;
		font-size: 10px;
	}

	.pass {
		background-color: #3485fb;
	}

	.spusers {
		padding: 10px;
	}

	.user {
		display: inline-block;
		margin: 0 10px 8px 0;
		line-height: 22rpx;
		border: 1px solid #3BB0F2;
		padding: 3px 6px;
		color: #3BB0F2;
		border-radius: 2px;
	}

	.spfail {
		border: 1px solid orange;
	}

	.user img {
		display: inline-block;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		margin-right: 5px;
		vertical-align: middle;
		border: 1px solid #E6E6E6
	}

	.step_type {
		width: 15px;
		height: 15px;
		display: inline-block;
		vertical-align: middle;
		margin-left: 10px;
	}

	.handsign {
		width: 60px;
		height: 30rpx;
		display: inline-block;
		vertical-align: middle;
	}

	.handsign img {
		width: 100%;
		height: 100%;
	}

	.spuseritem {
		border-bottom: 1px solid #ddd;
		margin-left: 15px;
		margin-top: 8px;
	}

	.spusers .spuseritem:last-child {
		border-bottom: 0;
	}

	.spword {
		padding: 5px;
		background-color: #edf6ff;
		color: #888;
		font-size: 12px;
		word-break: break-all;
	}

	.steptype {
		display: inline-block;
		margin-left: 20px;
		font-size: 10px;
		background-color: #409EFF;
		color: #fff;
		padding:0 10px;
		border-radius: 4px;
		transform: scale(0.8,0.8);
	}
</style>
