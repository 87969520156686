<template>
	<div>
		<el-dialog title="耗材用量" :visible.sync="dialogshow" :close-on-click-modal="false" :modal-append-to-body="false" :destroy-on-close="true" @close="closeDialog"
			width="800px">
			<div>

				<el-form :model="EditItem" ref="EditItem" label-width="120px">
					
					<el-row style="padding-top: 10px;">
						<el-col :span="8">
							<el-form-item label="报修地点" required>
								<el-select v-model="EditItem.location">
									<el-option label="机房" value="机房"></el-option>
									<el-option label="班级" value="班级"></el-option>
									<el-option label="其他" value="其他"></el-option>
								</el-select>			
								
							</el-form-item>
						</el-col>
						<el-col :span="8">
							<el-form-item label="故障类型" required>
							<el-select v-model="EditItem.bxtype">
								<el-option label="电脑故障" value="电脑故障"></el-option>
								<el-option label="网络故障" value="网络故障"></el-option>
								<el-option label="办公设备" value="办公设备"></el-option>
								<el-option label="班级多媒体" value="班级多媒体"></el-option>
							</el-select>			
							
							</el-form-item>
						</el-col>
					</el-row>
					<el-form-item label="报修位置" required>
						<el-input type="text" v-model="EditItem.address" placeholder="请输入故障设备房间位置具体信息"></el-input>	
					</el-form-item>

					<el-form-item label="故障描述" required>
						<el-input type="textarea" v-model="EditItem.content" placeholder="请描述设备故障现象"></el-input>
					</el-form-item>
					
					<el-form-item label="故障照片">
						<div>
							<div class="fileitem" v-for="(f,idx) in EditItem.files" :key="idx">
								{{f.fileName}}
								<div class="remove" @click="removeAtt(idx)"></div>
							</div>
							<div style="display: inline-block;">
								<el-upload action="" :multiple="true" :http-request="uploadOss"
									:on-success="uploadAttch" :show-file-list="false" name="image">

									<el-button size="mini">+</el-button>
								</el-upload>
							</div>
						</div>
					</el-form-item>



				</el-form>
				
				<el-row>
					<processchoose :code="process_code" v-on:ischooseuser="isChooseuser" v-on:chooseUser="chooseUser"></processchoose>
				</el-row>
				
				
				
				<el-row style="text-align: right;">
					<el-button type="primary" icon="el-icon-s-claim" @click="saveForm(0)">保存</el-button>
				</el-row>

			</div>
		</el-dialog>
	</div>
</template>

<script>
	import processchoose from "../com/processchoose.vue"
	export default {
		components:{processchoose},
		data() {
			return {
				dialogshow: true,
				userinfo: null,
				EditItem: {
					id: 0,
					title: "",
					location: "",
					address: "",
					bxtype:"",
					content: "",
					files: [],
					process_id: 0,
					remark: "",
					status: 1

				},
				process_code:"zichan",
				needChoose:false,//是否需要选择下级
				choosedUser:[],//已选择的用户
				
			}

		},
		mounted() {
			this.getTeacheInfo()
			this.getProcessID()
		},
		methods: {
			getProcessID(num) {
				this.$http.post("/api/form_process", {
					process_code: this.process_code
				}).then(res => {
					this.EditItem.process_id = res.data.id
				})
			},
			getTeacheInfo() {
				this.$http.post("/api/get_teacher_info").then(res => {
					this.userinfo = res.data
				})
			},
			closeDialog(){
				this.$parent.dialogshow = false
			},
			uploadAttch(e) {

				this.EditItem.files.push(e)
			},
			calcTotalDay(lastcheck) {
				let DataInfo = this.EditItem;

				if (DataInfo.start_date && DataInfo.end_date) {
					let start = new Date(DataInfo.start_date).getTime();
					let end = new Date(DataInfo.end_date).getTime();
					if (end < start) {
						this.$message({
							type: 'error',
							message: '结束日期错误！'
						});

						return false;
					}
					return true;
				} else {
					if (lastcheck) {
						this.$message({
							type: 'error',
							message: '请填写完整'
						});
					}
					return false;
				}
				
			},
			
			preCheckData(e) {

				let DataInfo = this.EditItem;


				
				if (!DataInfo.location) {

					this.$message({
						type: 'error',
						message: '请选择报修地点'
					});
					return false;
				}
				if (!DataInfo.address) {
				
					this.$message({
						type: 'error',
						message: '请输入报修位置'
					});
					return false;
				}
				if (!DataInfo.bxtype) {
				
					this.$message({
						type: 'error',
						message: '请选择故障类型'
					});
					return false;
				}

				if (!DataInfo.content) {

					this.$message({
						type: 'error',
						message: '请输入故障描述'
					});
					return false;
				}
				
				if(this.needChoose&&this.choosedUser.length==0){
					this.$message({
						type: 'error',
						message: '请选择审批人'
					});
					return false;
				}
				return true

			},
			saveForm() {
				
				if (!this.preCheckData()) {
					return
				}
				let EditItem = JSON.parse(JSON.stringify(this.EditItem));
				EditItem.sp_status = 0;
				EditItem.files = JSON.stringify(EditItem.files);
				EditItem.title = "[" + this.userinfo.dept_name + "]" + this.userinfo.username + "申请耗材用量"

				this.$http.post("/api/zichan_list_edit", EditItem).then(res => {
					if(!res.data.code||res.data.code==200){
						this.$message({
							type: 'success',
							message: '保存成功'
						});
						this.$parent.getList();
						this.$parent.dialogshow = false;
					}else{
						this.$message({
							type: 'error',
							message: res.data.msg
						});
					}
					
				})
			},
			isChooseuser(e){//设置是否需要选人
				this.needChoose = e
			},
			chooseUser(e){//设置选择的人
				
				this.choosedUser=e
				
			}
		},
	}
</script>

<style>
</style>
