<template>
	<div class="cbox">
		<div class="headbar">
			<div style="float: right;">
				<el-form :inline="true" :model="queryForm" style="">
					<el-form-item label="标题" min-width="120">
						<el-input size="small" v-model="queryForm.keyword" placeholder="请输入关键字"></el-input>
					</el-form-item>


					<el-form-item label="故障类型" min-width="120">
						<el-select v-model="EditItem.bxtype" size="small">
							<el-option label="电脑故障" value="电脑故障"></el-option>
							<el-option label="网络故障" value="网络故障"></el-option>
							<el-option label="办公设备" value="办公设备"></el-option>
							<el-option label="班级多媒体" value="班级多媒体"></el-option>
						</el-select>	
					</el-form-item>
					


					<el-form-item>
						<el-button size="small" type="primary" @click="getList()">搜索</el-button>
						<el-button style="margin-left: 10px;" icon="el-icon-plus" size="small" type="primary"
							@click="addNew">
							我要报修</el-button>

					</el-form-item>
				</el-form>
			</div>
			<div style="padding-top: 14px;">
				<el-breadcrumb>
					<el-breadcrumb-item>耗材用量</el-breadcrumb-item>
					<el-breadcrumb-item>列表</el-breadcrumb-item>

				</el-breadcrumb>
			</div>

		</div>



		<div class="bcontent">
			<el-table stripe :data="DataList" style="width: 100%;margin-bottom: 20px; color: #000;" border size="mini">
				<el-table-column type="index" width="55" label="序号">
				</el-table-column>

				<el-table-column prop="username" label="发起人" width="100"></el-table-column>
				<el-table-column prop="title" label="标题" min-width="120"></el-table-column>

				<el-table-column prop="location" label="报修地点" width="100">
					
				</el-table-column>
				<el-table-column prop="bxtype" label="故障类型" width="100">
					
				</el-table-column>
				<el-table-column prop="address" label="报修位置"  min-width="100">
					
				</el-table-column>
				
				<el-table-column prop="content" label="故障描述" min-width="120"></el-table-column>

				<el-table-column prop="sp_status" label="状态" width="80">
					<template slot-scope="scope">
						<span v-if="scope.row.sp_status==1&&!scope.row.pj_star" style="color: green;">已维修</span>
						<span v-if="scope.row.sp_status==1&&scope.row.pj_star" style="color: green;">已评价</span>
						<span v-if="scope.row.sp_status==2" style="color: blue;">已拒绝</span>

						<span v-if="scope.row.sp_status==0" style="color: gray;">未审核</span>
					</template>
				</el-table-column>

				<el-table-column prop="cdate" label="申请时间" width="150"></el-table-column>



				<el-table-column fixed="right" label="操作" width="120">
					<template slot-scope="scope">
						<el-popconfirm width="200" confirmButtonText='确定' cancelButtonText='取消' icon="el-icon-info"
							iconColor="red" @confirm="handleDel(scope.row)" placement="left" title="是否确定删除？">
							<a slot="reference" size="small"
								v-if="(scope.row.sp_status!=1&&scope.row.create_uid==$store.state.teacher.id)||$store.state.teacher.isadmin"
								style="color: #ccc;font-size: 12px;margin-right: 10px; cursor: pointer;">删除</a>
						</el-popconfirm>
						<el-button type="text" size="small" @click="goSp(scope.row)">详情</el-button>
						<el-button style="color: orangered;" type="text" size="small" v-if="scope.row.icansp"
							@click="goSp(scope.row)">审核</el-button>
							
						<el-button style="color: orangered;" type="text" size="small" v-if="scope.row.sp_status==1&&!scope.row.pj_star&&userinfo.id==scope.row.create_uid"
							@click="goSp(scope.row)">评价</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="height: 20px;">
				
			</div>


		</div>
		<div style="position: absolute; bottom: 0; height: 40px; width: 100%; text-align: right; background-color: #fff; z-index: 20;">
			<el-pagination @current-change="handlePageChange" :current-page="page.current_page"
				:page-size="page.per_page" :total="page.count" layout="total, prev, pager, next">
			</el-pagination>
		</div>
		<formdialog v-if="dialogshow" :id="EditItem.id"></formdialog>
		<formsp v-if="dialogspshow" :id="EditItem.id"></formsp>
	</div>
</template>

<script>
	import formdialog from "./formdialog.vue"
	import formsp from "./formsp.vue"
	export default {
		components: {
			formdialog,
			formsp
		},
		data() {
			return {
				temphead: "//school.mmteck.cn/static/temp.png",

				dialogshow: false,
				dialogspshow: false,
				queryForm: {
					type: "title",
					keyword: "",
					dept_id: "",
					bxtype: ""
				},
				EditItem: {
					id: 0,
					group_name: "",
					needpic: "",
					outside: "",
					latesign: "",
				},
				DataList: [],
				userinfo:{},

				page: {
					count: 0,
					current_page: 1,
					per_page: 20,
					total_page: 0
				}


			};
		},
		mounted() {
			this.$http.post("/api/get_teacher_info", {
				
			}).then(res=>{
				this.userinfo = res.data
				this.getList()
			})
			
		},
		methods: {
			getList() {
				this.$http.post("/api/zichan_list_list", {
					page: this.page.current_page,
					pagesize: this.page.per_page,
					bxtype:this.queryForm.bxtype,
					ktype:this.queryForm.type,
					keyword:this.queryForm.keyword
				}).then(res => {
					this.getSpList(res.data.data)

					this.page = res.data.page
				})
			},

			getSpList(data) {

				this.$http.post("/api/zichan_list_list", {
					page: 1,
					pagesize: 50,
					datatype: "myspwait"
				}).then(res => {
					for (let item of res.data.data) {
						for (let row of data) {
							if (row.id == item.id) {
								row.icansp = true
								break
							}
						}
					}


					this.DataList = data
				})
			},










			handlePageChange(page) {
				this.page.current_page = page
				this.getList()
			},
			addNew() {
				this.EditItem.id = 0;
				this.dialogshow = true;
			},
			handleDel(row) {
				this.$http.post("/api/zichan_list_delete", {
					id: row.id
				}).then(res => {
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
					this.getList()
				})
			},
			goSp(e) {
				console.log(e)
				this.EditItem.id = e.id;
				this.dialogspshow = true
			}
		},
	}
</script>

<style scoped="scoped">
	.spuser {
		display: inline-block;
		border: 1px solid rgb(255, 148, 62);


		padding: 2px;
		margin-right: 10px;
		margin-bottom: 5px;
		border-radius: 4px;
		padding: 4px 10px;
	}

	.spuser img {
		width: 20px;
		height: 20px;
		display: inline-block;
		vertical-align: top;
		border-radius: 50%;
	}
</style>
