<template>
	<div>
		<!-- 这里改 -->
		<el-dialog title="耗材用量审批" :visible.sync="dialogshow" :close-on-click-modal="false" :modal-append-to-body="false"
			:destroy-on-close="true" @close="closeDialog" width="1000px">
			<div style="width: 450px; float: left; ">


				<table class="dtable" v-if="EditItem">
					<tr>
						<td style="width: 100px;">标题</td>
						<td>{{EditItem.title}}</td>
					</tr>
					<tr>
						<td>申请人</td>
						<td>{{EditItem.username}}</td>
					</tr>
					<tr>
						<td>申请时间</td>
						<td>{{EditItem.cdate}}</td>
					</tr>
					<tr>
						<td>报修地点</td>
						<td>{{EditItem.location}}</td>
					</tr>

					<tr>
						<td>报修位置</td>
						<td>{{EditItem.address}}</td>
					</tr>
					<tr>
						<td>故障类型</td>
						<td>{{EditItem.bxtype}}</td>
					</tr>
					<tr>
						<td>故障描述</td>
						<td>{{EditItem.content}}</td>
					</tr>

					<tr v-if="fileList.length>0">
						<td>附件</td>
						<td>
							<div class="fileitem" v-for="(file,idx) in fileList" :key="idx">
								<div v-if="file.type=='image'"
									style="width: 100%; height: 100%; background-size: cover;"
									:style="{backgroundImage:'url('+file.src+')'}">
									<img :src="file.src" v-viewer style="width: 100%; height: 100%; opacity: 0;">
								</div>
								<div v-if="file.type!='image'" @click="openDoc(file)"
									style="width: 100%; height: 100%; background-size: cover; line-height: initial; overflow: hidden;">
									<i class="el-icon-document"></i><span
										style="font-size: 12px;">{{file.fileName}}</span>
								</div>
							</div>


						</td>
					</tr>

				</table>
			</div>

			<div style="margin-left: 500px;min-height: 500px;">
				<processsp v-if="EditItem" :forminfo="EditItem"></processsp>
			</div>
			<div v-if="EditItem&&canpingjia" style="text-align: right;">


				<div style="pingjia">
					<span style="vertical-align: top; display: inline-block;line-height: 35px;">维修评价：</span>
					<div style="display: inline-block; width: 400px; text-align: left; font-size: 30px;">
						<i :class="['el-icon-star-on',EditItem.pj_star>0?'haoping':'']" title="1星,非常不满意"
							@click="EditItem.pj_star=1" style="cursor: pointer;"></i>
						<i :class="['el-icon-star-on',EditItem.pj_star>1?'haoping':'']" title="2星,不满意"
							@click="EditItem.pj_star=2" style="margin-left: 10px;cursor: pointer;"></i>
						<i :class="['el-icon-star-on',EditItem.pj_star>2?'haoping':'']" title="3星,一般"
							@click="EditItem.pj_star=3" style="margin-left: 10px;cursor: pointer;"></i>
						<i :class="['el-icon-star-on',EditItem.pj_star>3?'haoping':'']" title="4星,满意"
							@click="EditItem.pj_star=4" style="margin-left: 10px;cursor: pointer;"></i>
						<i :class="['el-icon-star-on',EditItem.pj_star>4?'haoping':'']" title="5星,非常满意"
							@click="EditItem.pj_star=5" style="margin-left: 10px;cursor: pointer;"></i>
					</div>
					<br /><br />
					维修评语：<el-input type="text" style="width: 400px;" placeholder="服务好不好,维修快不快,哪里需要改进,请留下您的宝贵意见"
						v-model="EditItem.pj_word"></el-input>

					<div style="padding-top: 20px;">
						<el-button type="primary" @click="submitPingjia">提交评价</el-button>
					</div>

				</div>

			</div>

			<div v-if="EditItem&&EditItem.pj_star&&!canpingjia" style="text-align: right;">


				<div style="pingjia">
					<span style="vertical-align: top; display: inline-block;line-height: 35px;">维修评价：</span>
					<div style="display: inline-block; width: 400px; text-align: left; font-size: 30px;">
						<i :class="['el-icon-star-on',EditItem.pj_star>0?'haoping':'']" title="1星,非常不满意"
							style="cursor: pointer;"></i>
						<i :class="['el-icon-star-on',EditItem.pj_star>1?'haoping':'']" title="2星,不满意"
							style="margin-left: 10px;cursor: pointer;"></i>
						<i :class="['el-icon-star-on',EditItem.pj_star>2?'haoping':'']" title="3星,一般"
							style="margin-left: 10px;cursor: pointer;"></i>
						<i :class="['el-icon-star-on',EditItem.pj_star>3?'haoping':'']" title="4星,满意"
							style="margin-left: 10px;cursor: pointer;"></i>
						<i :class="['el-icon-star-on',EditItem.pj_star>4?'haoping':'']" title="5星,非常满意"
							style="margin-left: 10px;cursor: pointer;"></i>
					</div>
					<br /><br />
					<span style="vertical-align: top; display: inline-block;">维修评语：</span><div style="display: inline-block; width: 400px; text-align: left; font-size: 30px;">
						<span style="color: blue; font-size: 14px; line-height: 20px; display: inline-block; ">{{EditItem.pj_word}}</span>
					</div>

				</div>

			</div>


		</el-dialog>
	</div>
</template>

<script>
	import processsp from './processsp.vue'
	export default {
		props: ["id"],
		components: {
			processsp
		},
		data() {
			return {
				dialogshow: true,
				EditItem: null,
				canpingjia: false,
				userinfo: {},
				fileList: []

			}

		},
		mounted() {
			this.$http.post("/api/get_teacher_info", {

			}).then(res => {
				this.userinfo = res.data
				this.getInfo()
			})



		},
		methods: {

			getInfo() {
				this.$http.post("/api/zichan_one", {
					id: this.id
				}).then(res => {
					try {
						res.data.files = JSON.parse(res.data.files)
					} catch (e) {}
					try {
						res.data.process_json = JSON.parse(res.data.process_json)
					} catch (e) {}
					this.EditItem = res.data
					if (res.data.sp_status == 1 && !res.data.pj_star && res.data.create_uid == this.userinfo
						.id) { //审批通过并且没有评价的都可以评价
						this.canpingjia = true
					}else{
						this.canpingjia = false
					}
					this.initFileList(res.data.files)

				})
			},
			closeDialog() {
				this.$parent.dialogspshow = false
			},
			initFileList(files) {
				let arr = []
				for (let item of files) {
					let filename = item.src.toLowerCase()
					let fitem = {
						type: "",
						src: item.src,
						fileName: item.fileName
					}
					if (filename.indexOf(".jpg") > 0 || filename.indexOf(".jpeg") > 0 || filename.indexOf(".png") >
						0 || filename.indexOf(".webp") > 0) {
						fitem.type = "image"
					} else if (filename.indexOf(".mp3") > 0 || filename.indexOf(".wav") > 0) {
						fitem.type = "audio"
					} else if (filename.indexOf(".doc") > 0 || filename.indexOf(".docx") > 0 ||
						filename.indexOf(".doc") > 0 || filename.indexOf(".docx") > 0 ||
						filename.indexOf(".xls") > 0 || filename.indexOf(".xlsx") > 0 ||
						filename.indexOf(".ppt") > 0 || filename.indexOf(".pptx") > 0) {
						fitem.type = "file"
					}
					arr.push(fitem)



				}
				this.fileList = arr

			},
			openDoc(file) {
				window.open(file.src, "_blank")
			},
			submitPingjia() {
				if (!this.EditItem.pj_star) {
					this.$message({
						type: 'error',
						message: '请选择评价星级'
					});
				}
				
				this.$http.post("/api/zichan_pingjia", this.EditItem).then(res => {
					this.getInfo()
					this.$message.success("评价成功")
					this.$parent.getList()
				
				})

			}


		},
	}
</script>

<style scoped="scoped">
	.dtable,
	.dtable tr th,
	.dtable tr td {
		border: 1px solid #c0c0c0;
		text-align: left;
		padding: 10px;
	}

	.dtable tr td:nth-child(1) {
		font-weight: bold;
	}

	.dtable {
		width: 100%;
		min-height: 25px;
		line-height: 25px;
		text-align: center;
		border-collapse: collapse;
		padding: 2px;
	}

	.fileitem {
		width: 60px;
		height: 60px;
		display: inline-block;
		border-radius: 4px;
		border: 1px solid #ECECEC;
		margin-right: 10px;
		margin-bottom: 10px;
		vertical-align: top;
		cursor: pointer;
	}

	.haoping {
		color: #ffea01;
	}
</style>
